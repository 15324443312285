import { useNSTranslation } from '@holmanfm/lib/lang';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from '@holmanfm/lib/redux';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import { formatPhoneNumber } from '@holmanfm/lib/common/marketplace-helpers';
import useDealerQuoteRequest from '@holmanfm/lib/hooks/dealer-portal/use-dealer-quote-request';
import useDialog from '@holmanfm/lib/hooks/use-dialog';
import useSelectedLeasingOptions from '~/areas/dealer-portal/payment-estimator/use-selected-leasing-options';
import Button from '~/shared/components/atom/button';
import Typography from '~/shared/components/atom/typography';
import PageTitle from '~/shared/components/molecule/page-title';
import useEstimator from '~/areas/dealer-portal/payment-estimator/use-estimator';
import CircularProgress from '~/shared/components/circular-progress';
import typeStyles from '~/shared/components/styles/global-type-styles';
import LeasingChart from '~/areas/dealer-portal/payment-estimator/leasing-chart';
import Add from '~/shared/icons/add';
import FinancialInformationDialog from '~/areas/dealer-portal/payment-estimator/financial-information-dialog';
import UpdateEstimateDialog from '~/areas/dealer-portal/payment-estimator/update-estimate-dialog';
import ServerMessages from '~/shared/components/messages/server-messages';
import ErrorDialog from '~/shared/components/error-dialog';
import SubmittedQuoteInformation from '~/areas/dealer-portal/payment-estimator/submitted-quote-information';
import CancelEstimateDialog from '~/areas/dealer-portal/payment-estimator/cancel-estimate-dialog';
import MessageCenter from './message-center';

const ResumeEstimator = () => {
  const typeClasses = typeStyles();
  const history = useHistory();
  const { t } = useNSTranslation('dealerPortal', 'payment-estimator');
  const { workflowId } = useParams();
  const [estimateLeasingOptions, setEstimateLeasingOptions] = useState();
  const [leasingOptions, setLeasingOptions] = useState();
  const [processingUpdate, setProcessingUpdate] = useState(false);
  const [updateError, setUpdateError] = useState();
  const orgParentData = useSelector(selectors.getOrgData);
  const isDealerGroup = orgParentData?.dealerType === 'Group';

  const {
    chosenOptions,
    setChosenOptions,
    updateSelection,
    selectedLease,
    setSelectedLease,
  } = useSelectedLeasingOptions();

  const {
    printEstimate,
    saveToQuote,
    updateEstimateData,
    getCreditContractData,
    creditContractStatus,
    creditAssetTypes,
  } = useEstimator();

  const {
    savedEstimate,
    savedEstimateRequest,
    orgData: orgDataCopy,
    errorSavedEstimate,
    errorOrgData,
    statusSavedEstimate,
  } = useDealerQuoteRequest(workflowId);

  const orgData = orgDataCopy?.data;
  const quoteSubmitted = savedEstimate?.state === 'quote_submitted';
  const estimateCancelled = savedEstimate?.state === 'estimate_canceled';

  const {
    open: financialOpen,
    openDialog: financialOpenDialog,
    closeDialog: financialCloseDialog,
  } = useDialog(false);

  const {
    open: updateOpen,
    openDialog: updateOpenDialog,
    closeDialog: updateCloseDialog,
  } = useDialog(false);

  const {
    open: errorOpen,
    openDialog: errorOpenDialog,
    closeDialog: errorCloseDialog,
  } = useDialog(false);

  const {
    open: cancelOpen,
    openDialog: cancelOpenDialog,
    closeDialog: cancelCloseDialog,
  } = useDialog(false);

  useEffect(() => {
    if (savedEstimateRequest) {
      setEstimateLeasingOptions(savedEstimateRequest?.financial);
      setLeasingOptions(savedEstimateRequest?.leasing);
      const chosenEstimate = savedEstimateRequest?.leasing.filter(
        x => x.selected
      );
      setChosenOptions(chosenEstimate);
    }
    if (savedEstimateRequest?.org?.orgId) {
      getCreditContractData(savedEstimateRequest?.org?.orgId);
    }
  }, [savedEstimateRequest]);

  const newClientInfo =
    !savedEstimateRequest?.org?.orgId &&
    !!savedEstimateRequest?.org?.organizationName;

  if (statusSavedEstimate === 'rejected') {
    return <ServerMessages messages={errorSavedEstimate} />;
  }
  if (errorSavedEstimate || errorOrgData) {
    return <ServerMessages messages={errorSavedEstimate || errorOrgData} />;
  }
  if (statusSavedEstimate === 'pending' || !orgData) {
    return <CircularProgress />;
  }

  return (
    <div>
      <div style={{ marginBottom: 16, maxWidth: '65%' }}>
        <PageTitle>{t('quote-payment-title')}</PageTitle>
      </div>
      <div className={typeClasses.driveBlue} style={{ marginBottom: 16 }}>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
          {t('vehicle-order-number')} {savedEstimate.requestId}
        </Typography>
      </div>
      <div>
        <Typography className={typeClasses.driveBlueBold} variant="h6">
          {t('customer-information-title')}
        </Typography>
        <div style={{ display: 'flex' }}>
          <Typography className={typeClasses.driveBlue} variant="body2">
            {t('organization-name')}:
          </Typography>
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            {orgData?.organizationName}
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <Typography className={typeClasses.driveBlue} variant="body2">
            {t('contact-name')}
          </Typography>
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            {orgData?.contacts[0].firstName} {orgData?.contacts[0].lastName}
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <Typography className={typeClasses.driveBlue} variant="body2">
            {t('office-number')}:
          </Typography>
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            {formatPhoneNumber(orgData?.contacts[0].phoneOffice)}
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <Typography className={typeClasses.driveBlue} variant="body2">
            {t('mobile-number')}:
          </Typography>
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            {formatPhoneNumber(orgData?.contacts[0].phoneMobile)}
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <Typography className={typeClasses.driveBlue} variant="body2">
            {t('email-address')}:
          </Typography>
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            {orgData?.contacts[0].email}
          </Typography>
        </div>
      </div>

      {isDealerGroup && savedEstimateRequest?.vehicle?.dealerName && (
        <div style={{ marginTop: 24 }}>
          <Typography className={typeClasses.driveBlueBold} variant="h6">
            {t('assigned-dealership')}
          </Typography>
          <div>
            {savedEstimateRequest?.vehicle?.dealerName}
            <br />
            {savedEstimateRequest?.vehicle?.dealerCity}{' '}
            {savedEstimateRequest?.vehicle?.dealerState}{' '}
            {savedEstimateRequest?.vehicle?.dealerPostalCode}
          </div>
        </div>
      )}

      {savedEstimateRequest?.leasing && savedEstimateRequest?.asset && (
        <div style={{ paddingBottom: 24 }}>
          <LeasingChart
            chartTitle={t('original-estimate-info')}
            leasingOptions={
              leasingOptions?.creditRankUtilized
                ? leasingOptions?.leaseOptions
                : leasingOptions
            }
            estimateLeasingOptions={estimateLeasingOptions}
            assetInformation={savedEstimateRequest?.asset}
            customerInformation={orgData}
            updateSelection={updateSelection}
            chosenOptions={chosenOptions}
            quoteSubmitted={quoteSubmitted || estimateCancelled}
            selectedLease={selectedLease}
            setSelectedLease={setSelectedLease}
          />
        </div>
      )}

      <MessageCenter
        estimateLeasingOptions={estimateLeasingOptions}
        creditContractStatus={creditContractStatus}
        creditAssetTypes={creditAssetTypes}
        newClientInfo={newClientInfo}
        resumedEstimate
      />

      {quoteSubmitted && (
        <SubmittedQuoteInformation
          chosenOptions={chosenOptions}
          submittedData={
            savedEstimate?.steps?.filter(x => x.state === 'quote_submitted')[0]
          }
        />
      )}
      <div style={{ marginTop: 24 }}>
        {!quoteSubmitted && !estimateCancelled && (
          <>
            <Button
              color="secondary"
              variant="contained"
              style={{ marginRight: 16 }}
              disabled={
                !leasingOptions ||
                chosenOptions.length > 1 ||
                chosenOptions.length === 0 ||
                !creditContractStatus ||
                !estimateLeasingOptions?.creditAssetTypeId
              }
              onClick={() =>
                saveToQuote(
                  leasingOptions,
                  chosenOptions,
                  savedEstimateRequest.asset,
                  savedEstimateRequest.org.orgId,
                  estimateLeasingOptions,
                  workflowId,
                  savedEstimateRequest.creditRankUtilized
                )
              }
            >
              {t('submit-for-quote')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={financialOpenDialog}
              style={{ marginRight: 16 }}
            >
              <Add fontSize="small" /> {t('update-financial-information')}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              style={{ marginRight: 16 }}
              disabled={!leasingOptions}
              onClick={() =>
                printEstimate(
                  {
                    creditRankUtilized: savedEstimateRequest.creditRankUtilized,
                    leaseOptions: leasingOptions,
                  },
                  chosenOptions,
                  savedEstimateRequest.asset,
                  savedEstimateRequest?.org,
                  estimateLeasingOptions,
                  workflowId
                )
              }
            >
              {t('print-options')}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              style={{ marginRight: 16 }}
              disabled={!leasingOptions || processingUpdate}
              onClick={() => {
                setProcessingUpdate(true);
                updateEstimateData(
                  leasingOptions,
                  chosenOptions,
                  savedEstimateRequest.asset,
                  savedEstimateRequest?.org,
                  estimateLeasingOptions,
                  savedEstimateRequest.creditRankUtilized,
                  workflowId
                )
                  .then(() => {
                    updateOpenDialog();
                  })
                  .catch(err => {
                    setUpdateError(err);
                    errorOpenDialog();
                  });
                setProcessingUpdate(false);
              }}
            >
              {processingUpdate ? t('processing') : t('update-estimate')}
              {processingUpdate && (
                <CircularProgress
                  size={24}
                  style={{ marginTop: -12, marginLeft: -12 }}
                />
              )}
            </Button>
          </>
        )}
        <Button
          color="secondary"
          variant="text"
          onClick={
            quoteSubmitted || estimateCancelled
              ? () => history.push('/customer/request-management')
              : () => cancelOpenDialog()
          }
        >
          {quoteSubmitted || estimateCancelled
            ? t('close')
            : t('cancel-estimate')}
        </Button>
        {savedEstimateRequest.asset &&
          savedEstimateRequest.org.orgId &&
          updateOpen && (
            <UpdateEstimateDialog
              open={updateOpen}
              closeDialog={updateCloseDialog}
              estimateNumber={savedEstimate?.requestId}
              assetInformation={savedEstimateRequest.asset}
              customerInformation={savedEstimateRequest.org}
            />
          )}
        {updateError !== undefined && (
          <ErrorDialog
            open={errorOpen}
            closeDialog={errorCloseDialog}
            error={updateError?.[0]?.message}
          />
        )}
        <FinancialInformationDialog
          open={financialOpen}
          closeDialog={financialCloseDialog}
          setLeasingOptions={setLeasingOptions}
          setEstimateLeasingOptions={setEstimateLeasingOptions}
          asset={savedEstimateRequest.asset}
          estimateLeasingOptions={estimateLeasingOptions}
          setChosenOptions={setChosenOptions}
          dealerCustomerOrgId={savedEstimateRequest?.org?.orgId}
          creditAssetTypes={creditAssetTypes}
        />
        <CancelEstimateDialog
          open={cancelOpen}
          closeDialog={cancelCloseDialog}
          workflowId={workflowId}
        />
      </div>
    </div>
  );
};

export default ResumeEstimator;
